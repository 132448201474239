
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        























































































































.client-type,
[class*='client-type--'] {
  @include bg-color;

  ::v-deep {
    [class*='promo-banner--'] {
      margin-bottom: $spacing * 4;
      width: 100%;
    }
  }
}

.client-type__title,
.client-type__excerpt {
  font-family: $ff-alt;
  text-align: center;
}

.client-type__title {
  margin-top: 0;
  margin-bottom: $spacing;
}

.client-type__switcher {
  margin: $spacing * 2 0 $spacing * 2.5;
  text-align: center;
}

.client-type__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include mq(s) {
    margin: 0 -#{$spacing};
  }
}

.client-type__cta {
  margin-top: 5rem;
}
