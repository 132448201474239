
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        













































































.movers {
  ::v-deep {
    .steps {
      background: none;
    }

    .steps__inner {
      padding-right: 2rem;
      padding-left: 2rem;
    }

    .steps__list__item {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    .steps__list__item__title {
      width: 100%;
    }

    .steps__list__item__text {
      order: 1;
      margin: 0 0 $spacing;
    }

    .steps__list__item__link {
      margin-bottom: $spacing;
    }
  }
}

.movers__hero {
  color: white;
}

.movers__hero__link {
  margin-top: $spacing;
  text-align: left;
  ::v-deep {
    span {
      @include mq($until: m) {
        display: initial !important;
      }
    }
  }
}

.movers .smartphonecta {
  padding-top: 15rem;
  padding-bottom: 0;
}

.movers__sticky {
  position: fixed;
  z-index: 99;
  right: 2rem;
  bottom: 5rem;

  @include mq(l) {
    right: 0;
    bottom: 10rem;
  }
}

::v-deep .hero__slides__item__headline {
  > span {
    @extend %fw-medium;

    display: block;
    margin: $spacing / 2 0;
    font-family: $ff-alt;
    font-size: 2.2em / $body-text-s * 1em;
    line-height: 1.2;

    @include mq(m) {
      font-size: 2.8em / $body-text-m * 1em;
    }

    @include mq(l) {
      font-size: 3.2em / $body-text-l * 1em;
    }
  }
}
