
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        

























































































.smartphonecta,
[class*='smartphonecta--'] {
  @include bg-color;
  position: relative;
  z-index: 1;
  padding: $spacing * 2 0 $spacing * 3;
  color: $c-blue-dark;

  @include mq(m) {
    padding: $spacing * 2.5 0 $spacing * 4;
  }

  @include mq(l) {
    padding: $spacing * 5 0 $spacing * 6;
  }
}

.smartphonecta-inner {
  position: relative;
  display: flex;

  @include mq($until: l) {
    flex-direction: column-reverse;
  }
}

.smartphonecta__content {
  @include mq(l) {
    width: col(6);
    padding-left: col(1);
  }
}

.smartphonecta__content__title {
  margin-top: $spacing * 2;
  margin-bottom: 0;

  @include mq(l) {
    margin-top: 0;
  }
}

.smartphonecta__content__excerpt {
  @extend %fw-light;
  margin-top: $spacing;
  margin-bottom: 0;
}

.smartphonecta__content__button {
  margin-top: $spacing * 2;
}

.smartphonecta__content__link-outer {
  margin-top: $spacing;
}

.smartphonecta__speedlines {
  position: relative;
  z-index: -1;
  width: 100%;
  max-width: 54.5rem;
  margin: 0 auto;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  @include mq(l) {
    height: 54.5rem;
    transform: translateY(-10%);
  }
}
